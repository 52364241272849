// App.jsx
import React, { useState } from 'react';
// import QuotationForm from './components/QuotationForm';
// import QuotationCard from './components/QuotationCard';
import {Routes, Route} from 'react-router-dom';
import Apps from './Apps';
import Apss from './components/Apss';
import Sale from './components/sales/Sale';
// import { modelData } from './components/Data';

function App() {


  return (
    
    <Apps/>
    // <Sale/>
  );
}

export default App;