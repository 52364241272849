import QuotationForm from './QuotationForm';
import QuotationCard from './QuotationCard';
import ReactToPrint from 'react-to-print';
import { dealers } from './Dealers';
import LoginForm from './LoginForm';
import LandingPage from './LandingPage';
import React, { useState, useRef } from 'react';

function QuotationManager() {
  const [showQuotation, setShowQuotation] = useState(false);
  const [quotationData, setQuotationData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState('landing');
  const [selectedDealer, setSelectedDealer] = useState(dealers);  // Changed from currentDealer
  const componentRef = useRef();

  const handleQuotationSubmit = (data) => {
    setQuotationData(data);
    setShowQuotation(true);
  };

  const handleEdit = () => {
    setShowQuotation(false);
  };

  const handleLogin = (username, password, dealerCode) => {
    // Check if username and password are correct (you should use a more secure method in production)
    if (username === 'sainko' && password === '123456') {
      // Find the dealer with the matching unique code
      const dealer = dealers.find(d => d.uniqueCode === dealerCode);
      if (dealer) {
        setIsLoggedIn(true);
        setSelectedDealer(dealer);  // Changed from setCurrentDealer
        setCurrentPage('landing');
      } else {
        alert('Invalid dealer code');
      }
    } else {
      alert('Invalid credentials');
    }
  };

  const handleForgotPassword = () => {
    // Implement forgot password functionality
    alert('Forgot password functionality not implemented');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setCurrentPage('login');
    setSelectedDealer(null);  // Changed from setCurrentDealer
    // dealers={dealers}  
  };

  const handleStartQuotation = () => {
    setCurrentPage('quotation');
    setShowQuotation(false);
  };

  if (!isLoggedIn) {
    return <LoginForm onLogin={handleLogin} onForgotPassword={handleForgotPassword} />;
  }

  if (currentPage === 'landing') {
    return <LandingPage onStartQuotation={handleStartQuotation} onLogout={handleLogout} />;
  }

  return (
    <div>
      {showQuotation ? (
        <>
          <ReactToPrint
            trigger={() => (
              <button className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                Print/Download
              </button>
            )}
            content={() => componentRef.current}
          />
          <div ref={componentRef}>
            <QuotationCard {...quotationData} />
          </div>
          <button
            onClick={handleEdit}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit Quotation
          </button>
          <button
            onClick={() => setCurrentPage('landing')}
            className="mt-4 ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Back to Home
          </button>
        </>
      ) : (
        <>
          <QuotationForm 
            onSubmit={handleQuotationSubmit} 
            initialData={quotationData} 
            // dealer={selectedDealer}  // Changed from currentDealer

          />
          <button
            onClick={() => setCurrentPage('landing')}
            className="mt-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Back to Home
          </button>
        </>
      )}
    </div>
  );
}

export default QuotationManager;