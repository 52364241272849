import React, { useState, useEffect } from 'react';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { modelData } from './Data';

const modelCategories = {
  CPCB2: ['2185GMC2', '2205GMC2', '3255GMC2', '3285GMC2', '3335GMC2', '3385GMC2', '3445GMC2', '4575GMC2', '4725GMC2', '4905GMC2', '41035GMC2', 'M41565', '125KVA', '160KVA', '15LD440'],
  CPCB4: ['M2155G1', 'M2155G2', 'M3205G1', 'M3205G2', 'M3205G3', 'M3205G4', 'M3205G5', 'M4275G1', 'M4355G1', 'V4355G2','V4355G3', 'V4355G4', 'H4485G1', 'H6725G2', 'H6725G3', 'H6725G4', 'H6935G1', 'H6935G2'],
  IE: ['625DI', 'VNEF110'],
  OTHER: ['other'],
  
};

function QuotationForm({ onSubmit, initialData, ...otherProps }) {
  const [formData, setFormData] = useState(initialData || {
    logo: './images/sainko.jpeg',
    dealerName: 'SAINKO SYSTEMS',
    date: new Date().toISOString().split('T')[0],
    quoteNumber: `QUO/GEN/MAH/${new Date().getFullYear()}-${new Date().getFullYear() + 1}/`,
    customerName: '',
    customerCity: '',
    customerState: '',
    // customerGST: '',
    modelCategory: '',
    modelNo: '',
    items: []
  });

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if (name === 'modelCategory') {
      setFormData(prevData => ({
        ...prevData,
        modelNo: ''
      }));
    }

    if (name === 'modelNo') {
      const modelParts = modelData.filter(part => part.modal === value);
      setFormData(prevData => ({
        ...prevData,
        modelNo: value,
        items: modelParts.map(({ partno, name, qty, price, modal }) => ({ partno, name, qty, price, modal }))
      }));
    }
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...formData.items];
    newItems[index] = { ...newItems[index], [field]: value };
    setFormData(prevData => ({
      ...prevData,
      items: newItems
    }));
  };

  const addItem = () => {
    setFormData(prevData => ({
      ...prevData,
      items: [
        ...prevData.items, 
        { 
          partno: "", 
          name: "", 
          qty: "1", 
          price: "0", 
          modal: formData.modelNo 
        }
      ]
    }));
  };

  const removeItem = (index) => {
    setFormData(prevData => ({
      ...prevData,
      items: prevData.items.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dealerName">
          Dealer Name
        </label>
        <input
          type="text"
          id="dealerName"
          name="dealerName"
          value={formData.dealerName}
          readOnly
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="date">
          Date
        </label>
        <input
          type="date"
          id="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="quoteNumber">
          Quote Number
        </label>
        <input
          type="text"
          id="quoteNumber"
          name="quoteNumber"
          value={formData.quoteNumber}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerName">
          Customer Name
        </label>
        <input
          type="text"
          id="customerName"
          name="customerName"
          value={formData.customerName}
          onChange={handleChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div className="mb-4 flex space-x-4">
        <div className="w-1/3">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerCity">
            City
          </label>
          <input
            type="text"
            id="customerCity"
            name="customerCity"
            value={formData.customerCity}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="w-1/3">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerState">
            State
          </label>
          <input
            type="text"
            id="customerState"
            name="customerState"
            value={formData.customerState}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        {/* <div className="w-1/3">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerGST">
          GST Value
          </label>
          <input
            type="text"
            id="customerGST"
            name="customerGST"
            value={formData.customerGST}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div> */}
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="modelCategory">
          Model Category
        </label>
        <select
          id="modelCategory"
          name="modelCategory"
          value={formData.modelCategory}
          onChange={handleChange}
          className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Select a category</option>
          <option value="CPCB2">CPCB2</option>
          <option value="CPCB4">CPCB4</option>
          <option value="IE">IE</option>
          <option value="OTHER">OTHER</option>
          {/* <option value="Others">Others</option> */}
        </select>
      </div>

      {formData.modelCategory && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="modelNo">
            Model Number
          </label>
          <select
            id="modelNo"
            name="modelNo"
            value={formData.modelNo}
            onChange={handleChange}
            className="shadow appearance-none border cursor-pointer rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="">Select a model</option>
            {modelCategories[formData.modelCategory].map(model => (
              <option key={model} value={model}>{model}</option>
            ))}
          </select>
        </div>
      )}
       {formData.modelCategory === 'IE' && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="engineSrNo">
            Engine Sr. No.
          </label>
          <input
            type="text"
            id="engineSrNo"
            name="engineSrNo"
            value={formData.engineSrNo}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      )}

{/* {formData.modelCategory === 'Others' && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customModelNo">
            Custom Model Number
          </label>
          <input
            type="text"
            id="customModelNo"
            name="customModelNo"
            value={formData.customModelNo}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter custom model number"
          />
        </div>
      )} */}

      {formData.modelNo && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">Parts List</h3>
          <table className="w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-2 py-1">Sr No</th>
                <th className="px-2 py-1">Part No</th>
                <th className="px-2 py-1">Description</th>
                <th className="px-2 py-1">Qty</th>
                <th className="px-2 py-1">Price</th>
                <th className="px-2 py-1">Action</th>
              </tr>
            </thead>
            <tbody>
              {formData.items.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className="px-2 py-1 text-center">{index + 1}</td>
                  <td className="px-2 py-1">
                    <input
                      type="text"
                      value={item.partno}
                      onChange={(e) => handleItemChange(index, 'partno', e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  </td>
                  <td className="px-2 py-1">
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  </td>
                  <td className="px-2 py-1">
                    <input
                      type="text"
                      value={item.qty}
                      onChange={(e) => handleItemChange(index, 'qty', e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  </td>
                  <td className="px-2 py-1">
                    <input
                      type="text"
                      value={item.price}
                      onChange={(e) => handleItemChange(index, 'price', e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  </td>
                  <td className="px-2 py-1">
                    <button
                      type="button"
                      onClick={() => removeItem(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            type="button"
            onClick={addItem}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          >
            <FaPlus className="mr-2" /> Add Item
          </button>
        </div>
      )}

      <button
        type="submit"
        className="mt-6 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Generate Quotation
      </button>
    </form>
  );
}

export default QuotationForm;
