export const dealers = [
    {
      code: 1,
      name: 'SAINKO SYSTEMS',
      logo: './images/sainko.jpeg',
      quotePrefix: 'QUO/GEN/MAH/'
    },
    {
      code: 2,
      name: 'ANOTHER DEALER',
      // logo: 'https://example.com/another-dealer-logo.png',
      quotePrefix: 'QUO/GEN/DEA/'
    },
    // Add more dealers as needed
  ];