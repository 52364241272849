 export const modelData =[
    { sr: "", partno:"001082448R92-PB", gst: "28", name: "Fuel Filter primary", qty: "1", price: "150", modal: "15L440"},
{ sr: "", partno:"0037300880-Lom", name: "Fuel Filter Sec.", qty: "1", price: "268", modal: "15LD440", gst: "28", },
{ sr: "", partno:"507338", name: "Lube oil", qty: "2", price: "320", modal: "15LD440" , gst: "28",},
{ sr: "", partno:"0021751290-Lom", name: "Oil Filter", qty: "1", price: "354", modal: "15LD440" , gst: "18",},
{ sr: "", partno:"0021753070-Lom", name: "Air Filter", qty: "1", price: "2167", modal: "15LD440", gst: "05",},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "15LD440", gst: "10",},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "15LD440", gst: "18",},

{ sr: "", partno:"N/A", name: "", qty: "1", price: "", modal: "other"},
{ sr: "", partno:"N/A", name: "", qty: "1", price: "", modal: "other" },
{ sr: "", partno:"N/A", name: "", qty: "1", price: "", modal: "other" },


{ sr: "", partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "2185GMC2"},
{ sr: "", partno:"006017310B1-PB", name: "MDI Oil Filter", qty: "1", price: "212", modal: "2185GMC2" },
{ sr: "", partno:"507338", name: "Lube oil", qty: "6.75", price: "320", modal: "2185GMC2" },
{ sr: "", partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "2185GMC2" },
{ sr: "", partno:"006004403H1", name: "Hose Inlet", qty: "1", price: "233", modal: "2185GMC2"},
{ sr: "", partno:"006002543H1", name: "Hose Outlet", qty: "1", price: "383", modal: "2185GMC2"},
{ sr: "", partno:"005556140R1-PB", name: "Hose ByPass", qty: "1", price: "74", modal: "2185GMC2"},
{ sr: "", partno:"005556085R1-PB", name: "Alt Belt", qty: "1", price: "211", modal: "2185GMC2"},
{ sr: "", partno:"005554153R1-PB", name: "Fan Belt", qty: "1", price: "262", modal: "2185GMC2"},
{ sr: "", partno:"006011571H1", name: "Air Filter", qty: "1", price: "442", modal: "2185GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "2185GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "2185GMC2"},

{ sr: "",  partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "2205GMC2"},
{ sr: "",  partno:"006017310B1-PB", name: "MDI", qty: "1", price: "212", modal: "2205GMC2" },
{ sr: "",  partno:"507338", name: "Lube oil", qty: "6.75", price: "320", modal: "2205GMC2" },
{ sr: "",  partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "2205GMC2" },
{ sr: "",  partno:"006004403H1", name: "Hose Inlet", qty: "1", price: "170", modal: "2205GMC2"},
{ sr: "",  partno:"006002543H1", name: "Hose Outlet", qty: "1", price: "270", modal: "2205GMC2"},
{ sr: "",  partno:"005556140R1-PB", name: "Hose ByPass", qty: "1", price: "74", modal: "2205GMC2"},
{ sr: "", partno:"005556085R1-PB", name: "Alt Belt", qty: "1", price: "211", modal: "2205GMC2"},
{ sr: "", partno:"005554153R1-PB", name: "Fan Belt", qty: "1", price: "262", modal: "2205GMC2"},
{ sr: "", partno:"006011571H1", name: "Air Filter", qty: "1", price: "442", modal: "2205GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "2205GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "2205GMC2"},

{ sr: "", partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "3255GMC2"},
{ sr: "", partno:"006017310B1-PB", name: "MDI", qty: "1", price: "212", modal: "3255GMC2" },
{ sr: "", partno:"507338", name: "Lube oil", qty: "6.75", price: "320", modal: "3255GMC2" },
{ sr: "", partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "3255GMC2" },
{ sr: "", partno:"006004403H1", name: "Hose Inlet", qty: "1", price: "170", modal: "3255GMC2"},
{ sr: "", partno:"006002543H1", name: "Hose Outlet", qty: "1", price: "270", modal: "3255GMC2"},
{ sr: "",  partno:"005557797R1-PB", name: "Hose ByPass", qty: "1", price: "1770", modal: "3255GMC2"},
{ sr: "", partno:"005556085R1-PB", name: "Alt Belt", qty: "1", price: "211", modal: "3255GMC2"},
{ sr: "", partno:"005554153R1-PB", name: "Fan Belt", qty: "1", price: "262", modal: "3255GMC2"},
{ sr: "", partno:"006011571H1", name: "Air Filter", qty: "1", price: "442", modal: "3255GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "3255GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "3255GMC2"},

{ sr: "", partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "3285GMC2"},
{ sr: "",partno:"006017310B1-PB", name: "MDI", qty: "1", price: "212", modal: "3285GMC2" },
{ sr: "",partno:"507338", name: "Lube oil", qty: "6.75", price: "320", modal: "3285GMC2" },
{ sr: "",partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "3285GMC2" },
{ sr: "",partno:"006004403H1", name: "Hose Inlet", qty: "1", price: "170", modal: "3285GMC2"},
{ sr: "",partno:"006002543H1", name: "Hose Outlet", qty: "1", price: "270", modal: "3285GMC2"},
{ sr: "",  partno:"005557797R1-PB", name: "Hose ByPass", qty: "1", price: "77", modal: "3285GMC2"},
{ sr: "", partno:"005556085R1-PB", name: "Alt Belt", qty: "1", price: "211", modal: "3285GMC2"},
{ sr: "", partno:"005554153R1-PB", name: "Fan Belt", qty: "1", price: "262", modal: "3285GMC2"},
{ sr: "",partno:"006000456F1-PB", name: "Air Filter INNER", qty: "1", price: "557", modal: "3285GMC2"},
{ sr: "",partno:"006000455F1-PB", name: "Air Filter OUTER", qty: "1", price: "713", modal: "3285GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "3285GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "3285GMC2"},

{ sr: "", partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "3335GMC2"},
{ sr: "", partno:"006002508F1-PB", name: "Longer", qty: "1", price: "428", modal: "3335GMC2" },
{ sr: "", partno:"507338", name: "Lube oil", qty: "6.75", price: "320", modal: "3335GMC2" },
{ sr: "", partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "3335GMC2" },
{ sr: "", partno:"", name: "Hose Inlet", qty: "1", price: "170", modal: "3335GMC2"},
{ sr: "", partno:"006007283H1-PB", name: "Hose Outlet", qty: "1", price: "291", modal: "3335GMC2"},
{ sr: "",  partno:"005557797R1-PB", name: "Hose ByPass", qty: "1", price: "77", modal: "3335GMC2"},
{ sr: "", partno:"005556085R1-PB", name: "Alt Belt", qty: "1", price: "211", modal: "3335GMC2"},
{ sr: "", partno:"005554153R1-PB", name: "Fan Belt", qty: "1", price: "262", modal: "3335GMC2"},
{ sr: "", partno:"123", name: "Air Filter", qty: "1", price: "255", modal: "3335GMC2"},
{ sr: "",partno:"006000456F1-PB", name: "Air Filter INNER", qty: "1", price: "557", modal: "3335GMC2"},
{ sr: "",partno:"006000455F1-PB", name: "Air Filter OUTER", qty: "1", price: "713", modal: "3335GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "3335GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "3335GMC2"},

{ sr: "",  partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "3385GMC2"},
{ sr: "",  partno:"006002508F1-PB",name: "Longer", qty: "1", price: "428", modal: "3385GMC2" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "3385GMC2" },
{ sr: "",  partno:"N/A",name: "coolant", qty: "1", price: "239", modal: "3385GMC2" },
{ sr: "",  partno:"123", name: "Hose Inlet", qty: "1", price: "170", modal: "3385GMC2"},
{ sr: "",  partno:"123",name: "Hose Outlet", qty: "1", price: "270", modal: "3385GMC2"},
{ sr: "",  partno:"005557797R1-PB", name: "Hose ByPass", qty: "1", price: "77", modal: "3385GMC2"},
{ sr: "", partno:"005556085R1-PB", name: "Alt Belt", qty: "1", price: "211", modal: "3385GMC2"},
{ sr: "", partno:"005554153R1-PB", name: "Fan Belt", qty: "1", price: "262", modal: "3385GMC2"},   
{ sr: "",partno:"006000456F1-PB", name: "Air Filter INNER", qty: "1", price: "557", modal: "3385GMC2"},
{ sr: "",partno:"006000455F1-PB", name: "Air Filter OUTER", qty: "1", price: "713", modal: "3385GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "3385GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "3385GMC2"},

{ sr: "",  partno:"006001918A91-PB",name: "Service Kit", qty: "1", price: "278", modal: "4725GMC2"},
{ sr: "",  partno:"006002508F1-PB",name: "Longer", qty: "1", price: "428", modal: "4725GMC2" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "4725GMC2" },
{ sr: "",  partno:"N/A",name: "coolant", qty: "1", price: "239", modal: "4725GMC2" },
{ sr: "",  partno:"123",name: "Hose Inlet", qty: "1", price: "170", modal: "4725GMC2"},
{ sr: "",  partno:"123",name: "Hose Outlet", qty: "1", price: "270", modal: "4725GMC2"},
{ sr: "",  partno:"000020325E05-PB",name: "Fan Belt", qty: "1", price: "362", modal: "4725GMC2"},
{ sr: "",partno:"006000790F1-PB", name: "Air Filter INNER", qty: "1", price: "803", modal: "4725GMC2"},
{ sr: "",partno:"006000789F1-PB", name: "Air Filter OUTER", qty: "1", price: "1106", modal: "4725GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "4725GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "4725GMC2"},



{ sr: "", partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "3445GMC2"},
{ sr: "", partno:"006002508F1-PB", name: "Longer", qty: "1", price: "428", modal: "3445GMC2" },
{ sr: "", partno:"507338", name: "Lube oil", qty: "6.75", price: "320", modal: "3445GMC2" },
{ sr: "", partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "3445GMC2" },
{ sr: "", partno:"123", name: "Hose Inlet", qty: "1", price: "170", modal: "3445GMC2"},
{ sr: "", partno:"123", name: "Hose Outlet", qty: "1", price: "270", modal: "3445GMC2"},
{ sr: "",  partno:"005557797R1-PB", name: "Hose ByPass", qty: "1", price: "1770", modal: "3445GMC2"},
{ sr: "", partno:"005556085R1-PB", name: "Alt Belt", qty: "1", price: "211", modal: "3445GMC2"},
{ sr: "", partno:"005554153R1-PB", name: "Fan Belt", qty: "1", price: "262", modal: "3445GMC2"},
{ sr: "",partno:"006000456F1-PB", name: "Air Filter INNER", qty: "1", price: "557", modal: "3445GMC2"},
{ sr: "",partno:"006000455F1-PB", name: "Air Filter OUTER", qty: "1", price: "713", modal: "3445GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "3445GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "3445GMC2"},

{ sr: "", partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "4575GMC2" },
{ sr: "", partno:"006002508F1-PB", name: "Longer", qty: "1", price: "428", modal: "4575GMC2" },
{ sr: "",  partno:"000020325E05-PB",name: "Fan Belt", qty: "1", price: "362", modal: "4575GMC2"},
{ sr: "", partno:"507338", name: "Lube oil", qty: "8", price: "320", modal: "4575GMC2" },
{ sr: "", partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "4575GMC2" },
{ sr: "",  partno:"",name: "Hose Inlet", qty: "1", price: "170", modal: "4575GMC2"},
{ sr: "",  partno:"006007185H1-PB",name: "Hose Outlet", qty: "1", price: "383", modal: "4575GMC2"},
{ sr: "",  partno:"005557797R1-PB", name: "Hose ByPass", qty: "1", price: "77", modal: "4575GMC2"},
{ sr: "",partno:"006000790F1-PB", name: "Air Filter INNER", qty: "1", price: "803", modal: "4575GMC2"},
{ sr: "",partno:"006000789F1-PB", name: "Air Filter OUTER", qty: "1", price: "1106", modal: "4575GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "4575GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "4575GMC2"},



{ sr: "",  partno:"006001918A91-PB",name: "Service Kit", qty: "1", price: "278", modal: "4905GMC2" },
{ sr: "",  partno:"006010197H1",name: "Six Cylinder Filter", qty: "1", price: "440", modal: "4905GMC2" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "8", price: "320", modal: "4905GMC2" },
{ sr: "",  partno:"N/A",name: "coolant", qty: "1", price: "239", modal: "4905GMC2" },
{ sr: "",  partno:"006007186H1",name: "Hose Inlet", qty: "1", price: "318", modal: "4905GMC2"},
{ sr: "",  partno:"006007185H1",name: "Hose Outlet", qty: "1", price: "309", modal: "4905GMC2"},
{ sr: "",  partno:"006005659F2",name: "Hose ByPass", qty: "1", price: "55", modal: "4905GMC2"},
{ sr: "",  partno:"006016029h1",name: "Hose waterpump To", qty: "1", price: "416", modal: "4905GMC2"},
{ sr: "",  partno:"000020325E05-PB",name: "Fan Belt", qty: "1", price: "362", modal: "4905GMC2"},
{ sr: "",partno:"006000790F1-PB", name: "Air Filter INNER", qty: "1", price: "803", modal: "4905GMC2"},
{ sr: "",partno:"006000789F1-PB", name: "Air Filter OUTER", qty: "1", price: "1106", modal: "4905GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "4905GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "4905GMC2"},

{ sr: "",  partno:"006001918A91-PB",name: "Service Kit", qty: "1", price: "278", modal: "41035GMC2" },
{ sr: "",  partno:"006010197H1",name: "Six Cylinder Filter", qty: "1", price: "440", modal: "41035GMC2" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "8", price: "320", modal: "41035GMC2" },
{ sr: "",  partno:"N/A",name: "coolant", qty: "1", price: "239", modal: "41035GMC2" },
{ sr: "",  partno:"006011014H1",name: "Hose Inlet", qty: "1", price: "672", modal: "41035GMC2"},
{ sr: "",  partno:"006011903H1",name: "Hose Outlet T-LEFT", qty: "1", price: "177", modal: "41035GMC2"},
{ sr: "",  partno:"006011902H1",name: "Hose Radiaor T-side", qty: "1", price: "300", modal: "41035"},
{ sr: "",  partno:"006016029H1",name: "Hose waterpump To", qty: "1", price: "416", modal: "41035GMC2"},
{ sr: "",partno:"006000790F1-PB", name: "Air Filter INNER", qty: "1", price: "803", modal: "41035GMC2"},
{ sr: "",partno:"006000789F1-PB", name: "Air Filter OUTER", qty: "1", price: "1106", modal: "41035GMC2"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "41035GMC2"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "41035GMC2"},

{ sr: "",  partno:"0303BAV00170N",name: "Oil Filter", qty: "1", price: "605", modal: "125KVA" },
{ sr: "",  partno:"0305DAV00350N",name: "Fuel Filter", qty: "1", price: "588", modal: "125KVA" },
{ sr: "",  partno:"006049420H01",name: "Water Seperator", qty: "1", price: "1241", modal: "125KVA" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "20", price: "320", modal: "125KVA" },
{ sr: "",  partno:"N/A",name: "coolant", qty: "1", price: "239", modal: "125KVA" },
{ sr: "",partno:"0313AAV00100N", name: "Air Filter INNER", qty: "1", price: "1055", modal: "125KVA"},
{ sr: "",partno:"0313AAV00090N", name: "Air Filter OUTER", qty: "1", price: "4389", modal: "125KVA"},
{ sr: "",partno:"0306GAV00140N", name: "V Belt 2045L", qty: "1", price: "2471", modal: "125KVA"},
{ sr: "",partno:"006021986H1", name: "Door Hinge", qty: "1", price: "359", modal: "125KVA"},
{ sr: "",partno:"006025618H1", name: "Door Latch", qty: "1", price: "606", modal: "125KVA"},
{ sr: "",partno:"0304DAV00340N", name: "Hose Water outlet connection", qty: "1", price: "341", modal: "125KVA"},
{ sr: "",partno:"0304DAV00400N", name: "Hose Water in/out connection", qty: "1", price: "97", modal: "125KVA"},
{ sr: "",partno:"0304DAV00510N", name: "Hose Reducer Inlet", qty: "1", price: "167", modal: "125KVA"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "2500", modal: "125KVA"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "125KVA"},

{ sr: "",  partno:"0303BAV00170N",name: "Oil Filter", qty: "1", price: "605", modal: "M41565" },
{ sr: "",  partno:"0305DAV00350N",name: "Fuel Filter", qty: "1", price: "588", modal: "M41565" },
{ sr: "",  partno:"006049420H01",name: "Water Seperator", qty: "1", price: "1241", modal: "M41565" },
{ sr: "",  partno:"15W40",name: "Lube oil", qty: "15", price: "320", modal: "M41565" },
{ sr: "",  partno:"N/A",name: "coolant", qty: "1", price: "229", modal: "M41565" },
// { sr: "",partno:"0313AAV00100N", name: "Air Filter INNER", qty: "1", price: "1055", modal: "125KVA"},
// { sr: "",partno:"0313AAV00090N", name: "Air Filter OUTER", qty: "1", price: "4389", modal: "125KVA"},
// { sr: "",partno:"0306GAV00140N", name: "V Belt 2045L", qty: "1", price: "2471", modal: "125KVA"},
// { sr: "",partno:"006021986H1", name: "Door Hinge", qty: "1", price: "359", modal: "125KVA"},
// { sr: "",partno:"006025618H1", name: "Door Latch", qty: "1", price: "606", modal: "125KVA"},
// { sr: "",partno:"0304DAV00340N", name: "Hose Water outlet connection", qty: "1", price: "341", modal: "125KVA"},
// { sr: "",partno:"0304DAV00400N", name: "Hose Water in/out connection", qty: "1", price: "97", modal: "125KVA"},
// { sr: "",partno:"0304DAV00510N", name: "Hose Reducer Inlet", qty: "1", price: "167", modal: "125KVA"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "2500", modal: "M41565"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "M41565"},

{ sr: "",  partno:"0303BAV00170N",name: "Oil Filter", qty: "1", price: "605", modal: "160KVA" },
{ sr: "",  partno:"0305DAV00350N",name: "Fuel Filter", qty: "1", price: "588", modal: "160KVA" },
{ sr: "",  partno:"006049420H01",name: "Water Seperator", qty: "1", price: "1241", modal: "160KVA" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "20", price: "320", modal: "160KVA" },
{ sr: "",  partno:"N/A",name: "coolant", qty: "1", price: "239", modal: "160KVA" },
{ sr: "",partno:"0313AAV00100N", name: "Air Filter INNER", qty: "1", price: "1055", modal: "160KVA"},
{ sr: "",partno:"0313AAV00090N", name: "Air Filter OUTER", qty: "1", price: "4389", modal: "160KVA"},
{ sr: "",partno:"0306GAV00140N", name: "V Belt 2045L", qty: "1", price: "2471", modal: "160KVA"},
{ sr: "",partno:"006021986H1", name: "Door Hinge", qty: "1", price: "359", modal: "160KVA"},
{ sr: "",partno:"006025618H1", name: "Door Latch", qty: "1", price: "606", modal: "160KVA"},
{ sr: "",partno:"0304DAV00340N", name: "Hose Water outlet connection", qty: "1", price: "341", modal: "160KVA"},
{ sr: "",partno:"0304DAV00400N", name: "Hose Water in/out connection", qty: "1", price: "97", modal: "160KVA"},
{ sr: "",partno:"0304DAV00510N", name: "Hose Reducer Inlet", qty: "1", price: "167", modal: "160KVA"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "2500", modal: "160KVA"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "160KVA"},

{ sr: "",  partno:"006017309B91",name: "Oil Filter", qty: "1", price: "318", modal: "M2155G1" },
{ sr: "",  partno:"001082293R95",name: "Fuel Filter", qty: "1", price: "1029", modal: "M2155G1" },
// { sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M2155G1" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M2155G1" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M2155G1" },

{ sr: "",  partno:"006017309B91",name: "Oil Filter", qty: "1", price: "318", modal: "M2155G2" },
{ sr: "",  partno:"001082293R95",name: "Fuel Filter", qty: "1", price: "1029", modal: "M2155G2" },
// { sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M2155G1" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M2155G2" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M2155G2" },

{ sr: "",  partno:"006017309B91",name: "Oil Filter", qty: "1", price: "318", modal: "M3205G1" },
{ sr: "",  partno:"001082293R95",name: "Fuel Filter", qty: "1", price: "1029", modal: "M3205G1" },
// { sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M2155G1" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M3205G1" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M3205G1" },


{ sr: "",  partno:"006043765U91",name: "Main Fuel Filter-UFL", qty: "1", price: "2180", modal: "M3205G4" },
{ sr: "",  partno:"006039477V01",name: "Pre Fuel Filter", qty: "1", price: "914", modal: "M3205G4" },
{ sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M3205G4" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M3205G4" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M3205G4" },


{ sr: "",  partno:"006043765U91",name: "Main Fuel Filter-UFL", qty: "1", price: "2180", modal: "M3205G3" },
{ sr: "",  partno:"006039477V01",name: "Pre Fuel Filter", qty: "1", price: "914", modal: "M3205G3" },
{ sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M3205G3" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M3205G3" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M3205G3" },

{ sr: "",  partno:"006043765U91",name: "Main Fuel Filter-UFL", qty: "1", price: "2180", modal: "M3205G5" },
{ sr: "",  partno:"006039477V01",name: "Pre Fuel Filter", qty: "1", price: "914", modal: "M3205G5" },
{ sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M3205G5" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M3205G5" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M3205G5" },


{ sr: "",  partno:"006043765U91",name: "Main Fuel Filter-UFL", qty: "1", price: "2180", modal: "M4275G1"},
{ sr: "",  partno:"006039477V01",name: "Pre Fuel Filter", qty: "1", price: "914", modal: "M4275G1"},
{ sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M4275G1"},
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M4275G1"},
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M4275G1"},

{ sr: "",  partno:"006043765U91",name: "Main Fuel Filter-UFL", qty: "1", price: "2180", modal: "V4355G1"},
{ sr: "",  partno:"006039477V01",name: "Pre Fuel Filter", qty: "1", price: "914", modal: "V4355G1"},
{ sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M4275G1"},
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M4275G1"},
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M4275G1"},

{ sr: "",  partno:"006043765U91",name: "Main Fuel Filter-UFL", qty: "1", price: "2180", modal: "V4355G2"},
{ sr: "",  partno:"006039477V01",name: "Pre Fuel Filter", qty: "1", price: "914", modal: "V4355G2"},
{ sr: "",  partno:"006016642V91",name: "Oil Filter Ass.", qty: "1", price: "291", modal: "M4275G2"},
{ sr: "",  partno:"507338",name: "Lube oil", qty: "6.75", price: "320", modal: "M4275G2"},
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "M4275G2"},


// { sr: "",  partno:"006016642V91", name: "Oil Filter Assy", qty: "1", price: "250", modal: "V4355G4"},
// { sr: "",  partno:"E006018618D1",name: "Filter Insert", qty: "1", price: "2305", modal: "V4355G4" },
// { sr: "",  partno:"006012049H1",name: "Fuel Filter Elem", qty: "1", price: "328", modal: "V4355G4" },
// { sr: "",  partno:"006032997D1",name: "Element PCV Filter", qty: "1", price: "3051", modal: "V4355G4" },
// { sr: "",  partno:"507338",name: "Lube oil", qty: "13.5", price: "320", modal: "V4355G4" },
// { sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "V4355G4" },
// { sr: "",  partno:" 006032998D1", name: "O ring PCV Filter", qty: "1", price: "70", modal: "V4355G4"},
// { sr: "",  partno:" 0309CAV00331N", name: "Dosing Control Unit Filter", qty: "1", price: "2316", modal: "V4355G4"},
// { sr: "",  partno:"0313AAV00270N", name: "Primary Filter", qty: "1", price: "1710", modal: "V4355G4"},
// { sr: "",  partno:"0313AAV00280N", name: "Safety filter", qty: "1", price: "576", modal: "V4355G4"},

{ sr: "",  partno:"0303BAV00170N", name: "Oil Filter Assy", qty: "1", price: "605", modal: "H4485G1"},
{ sr: "",  partno:"006055667H01",name: "Cartridge Fuel Filter", qty: "1", price: "1945", modal: "H4485G1" },
{ sr: "",  partno:"006055668H01",name: "Element Fuel Filter BSVI", qty: "1", price: "1712", modal: "H4485G1" },
// { sr: "",  partno:"006032997D1",name: "Element PCV Filter", qty: "1", price: "3051", modal: "V4355G4" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "14.5", price: "320", modal: "H4485G1" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "H4485G1" },
// { sr: "",  partno:" 006032998D1", name: "O ring PCV Filter", qty: "1", price: "70", modal: "V4355G4"},
{ sr: "",  partno:" 0309CAV00331N", name: "Dosing Control Unit Filter", qty: "1", price: "2316", modal: "H4485G1"},
{ sr: "",  partno:"0313AAV00270N", name: "Primary Filter", qty: "1", price: "1710", modal: "H4485G1"},
{ sr: "",  partno:"0313AAV00280N", name: "Safety filter", qty: "1", price: "576", modal: "H4485G1"},

{ sr: "",  partno:"0303BAV00170N", name: "Oil Filter Assy", qty: "1", price: "605", modal: "H6725G2"},
{ sr: "",  partno:"006055667H01",name: "Cartridge Fuel Filter", qty: "1", price: "1945", modal: "H6725G2" },
{ sr: "",  partno:"006055668H01",name: "Element Fuel Filter BSVI", qty: "1", price: "1712", modal: "H6725G2" },
// { sr: "",  partno:"006032997D1",name: "Element PCV Filter", qty: "1", price: "3051", modal: "V4355G4" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "21.25", price: "320", modal: "H6725G2" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "H6725G2" },
// { sr: "",  partno:" 006032998D1", name: "O ring PCV Filter", qty: "1", price: "70", modal: "V4355G4"},
{ sr: "",  partno:" 0309CAV00331N", name: "Dosing Control Unit Filter", qty: "1", price: "2316", modal: "H6725G2"},
{ sr: "",  partno:"0313AAV00090N", name: "Primary Filter", qty: "1", price: "4389", modal: "H6725G2"},
{ sr: "",  partno:"0313AAV00100N", name: "Safety filter", qty: "1", price: "1055", modal: "H6725G2"},

{ sr: "",  partno:"0303BAV00170N", name: "Oil Filter Assy", qty: "1", price: "605", modal: "H6725G3"},
{ sr: "",  partno:"006055667H01",name: "Cartridge Fuel Filter", qty: "1", price: "1945", modal: "H6725G3" },
{ sr: "",  partno:"006055668H01",name: "Element Fuel Filter BSVI", qty: "1", price: "1712", modal: "H6725G3" },
// { sr: "",  partno:"006032997D1",name: "Element PCV Filter", qty: "1", price: "3051", modal: "V4355G4" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "21.25", price: "320", modal: "H6725G3" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "H6725G3" },
// { sr: "",  partno:" 006032998D1", name: "O ring PCV Filter", qty: "1", price: "70", modal: "V4355G4"},
{ sr: "",  partno:" 0309CAV00331N", name: "Dosing Control Unit Filter", qty: "1", price: "2316", modal: "H6725G3"},
{ sr: "",  partno:"0313AAV00090N", name: "Primary Filter", qty: "1", price: "4389", modal: "H6725G3"},
{ sr: "",  partno:"0313AAV00100N", name: "Safety filter", qty: "1", price: "1055", modal: "H6725G3"},

{ sr: "",  partno:"0303BAV00170N", name: "Oil Filter Assy", qty: "1", price: "605", modal: "H6725G4"},
{ sr: "",  partno:"006055667H01",name: "Cartridge Fuel Filter", qty: "1", price: "1945", modal: "H6725G4" },
{ sr: "",  partno:"006055668H01",name: "Element Fuel Filter BSVI", qty: "1", price: "1712", modal: "H6725G4" },
// { sr: "",  partno:"006032997D1",name: "Element PCV Filter", qty: "1", price: "3051", modal: "V4355G4" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "21.25", price: "320", modal: "H6725G4" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "H6725G4" },
// { sr: "",  partno:" 006032998D1", name: "O ring PCV Filter", qty: "1", price: "70", modal: "V4355G4"},
{ sr: "",  partno:" 0309CAV00331N", name: "Dosing Control Unit Filter", qty: "1", price: "2316", modal: "H6725G4"},
{ sr: "",  partno:"0313AAV00090N", name: "Primary Filter", qty: "1", price: "4389", modal: "H6725G4"},
{ sr: "",  partno:"0313AAV00100N", name: "Safety filter", qty: "1", price: "1055", modal: "H6725G4"},




{ sr: "",  partno:"0303BBA00231N", name: "Oil Filter Assy", qty: "1", price: "5319", modal: "H6935G1"},
{ sr: "",  partno:"0305DAV03030N",name: "Fuel Filter For I6 LCCR APL", qty: "1", price: "2085", modal: "H6935G1" },
{ sr: "",  partno:"7306AAP00241N",name: "Cartidge Fuel Filter BSVI", qty: "1", price: "3596", modal: "H6935G1" },
// { sr: "",  partno:"006032997D1",name: "Element PCV Filter", qty: "1", price: "3051", modal: "V4355G4" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "34.75", price: "11120", modal: "H6935G1" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "H6935G1" },
// { sr: "",  partno:" 006032998D1", name: "O ring PCV Filter", qty: "1", price: "70", modal: "V4355G4"},
{ sr: "",  partno:" 0309CAV00331N", name: "Dosing Control Unit Filter", qty: "1", price: "2316", modal: "H6935G1"},
{ sr: "",  partno:"0313ABA00211N", name: "Primary Filter", qty: "1", price: "5241", modal: "H6935G1"},
{ sr: "",  partno:"0313AAV00100N", name: "Safety filter", qty: "1", price: "1055", modal: "H6935G1"},


{ sr: "",  partno:"0303BBA00811N", name: "Oil Filter Assy", qty: "1", price: "4560", modal: "H6935G2"},
{ sr: "",  partno:"0303BAV00910N", name: "ByPass Filter spin on", qty: "1", price: "2783", modal: "H6935G2"},
{ sr: "",  partno:"0305DAV03030N",name: "Fuel Filter For I6 LCCR APL", qty: "1", price: "2085", modal: "H6935G2" },
{ sr: "",  partno:"7306AAP00241N",name: "Cartidge Fuel Filter BSVI", qty: "1", price: "3596", modal: "H6935G2" },
// { sr: "",  partno:"006032997D1",name: "Element PCV Filter", qty: "1", price: "3051", modal: "V4355G4" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "34.75", price: "11120", modal: "H6935G2" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "H6935G2" },
// { sr: "",  partno:" 006032998D1", name: "O ring PCV Filter", qty: "1", price: "70", modal: "V4355G4"},
{ sr: "",  partno:" 0309CAV00331N", name: "Dosing Control Unit Filter", qty: "1", price: "2316", modal: "H6935G2"},
{ sr: "",  partno:"0313ABA00211N", name: "Primary Filter", qty: "1", price: "5241", modal: "H6935G2"},
{ sr: "",  partno:"0313AAV00100N", name: "Safety filter", qty: "1", price: "1055", modal: "H6935G2"},


{ sr: "",  partno:"006016642V91",name: "Oil Filter", qty: "1", price: "250", modal: "VNEF110" },
{ sr: "",  partno:"006022696H1",name: "Filter Insert", qty: "1", price: "1808", modal: "VNEF110" },
{ sr: "",  partno:"006012049H1",name: "Fuel Filter 6cyl", qty: "1", price: "274", modal: "VNEF110" },
{ sr: "",  partno:"006000789F1-pb",name: "Air filter Outer", qty: "1", price: "803", modal: "VNEF110" },
{ sr: "",  partno:"006000790F1-pb",name: "Air Filter Inner", qty: "1", price: "1106", modal: "VNEF110" },
{ sr: "",  partno:"006032997D1",name: "Filer PCV Element", qty: "1", price: "3051", modal: "VNEF110" },
{ sr: "",  partno:"006032998D1",name: "O-ring PCV ", qty: "1", price: "70", modal: "VNEF110" },
{ sr: "",  partno:"0309CAV00331N",name: "Dozing control unit filter", qty: "1", price: "2316", modal: "VNEF110" },
{ sr: "",  partno:"0309CAV00461N",name: "Filter Head Unit", qty: "1", price: "343", modal: "VNEF110" },
{ sr: "",  partno:"507338",name: "Lube oil", qty: "13.5", price: "320", modal: "VNEF110" },
{ sr: "",  partno:"507341",name: "coolant", qty: "1", price: "239", modal: "VNEF110" },
{ sr: "",  partno:"006027140D1",name: "Belt 8PK cabin", qty: "1", price: "775", modal: "VNEF110" },
{ sr: "",  partno:"006010702F1",name: "Belt(8PK Poly V)", qty: "1", price: "786", modal: "VNEF110" },
{ sr: "",  partno:"000020325E05",name: "Belt Fan", qty: "1", price: "350", modal: "VNEF110" },
{ sr: "",  partno:"006015185H92",name: "Water Pump Ass.", qty: "1", price: "4338", modal: "VNEF110" },
{ sr: "",  partno:"E006026903D1",name: "Water Pump Ass tier4", qty: "1", price: "5317", modal: "VNEF110" },
{ sr: "",  partno:"NA",name: "Service Charges", qty: "1", price: "1770", modal: "VNEF110" },
{ sr: "",  partno:"NA",name: "Transpotation", qty: "1", price: "500", modal: "VNEF110" },



{ sr: "", partno:"006001918A91-PB", name: "Service Kit", qty: "1", price: "278", modal: "625DI"},
{ sr: "", partno:"006002508F1-PB", name: "Longer", qty: "1", price: "428", modal: "625DI" },
{ sr: "", partno:"N/A", name: "Lube oil", qty: "6.75", price: "320", modal: "625DI" },
{ sr: "", partno:"N/A", name: "coolant", qty: "1", price: "239", modal: "625DI" },
{ sr: "", partno:"123", name: "Hose Inlet", qty: "1", price: "170", modal: "625DI"},
{ sr: "", partno:"123", name: "Hose Outlet", qty: "1", price: "270", modal: "625DI"},
{ sr: "",  partno:"005557797R1-PB", name: "Hose ByPass", qty: "1", price: "1770", modal: "625DI"},
{ sr: "", partno:"006035200H1", name: "Engine Precleaner", qty: "1", price: "1016", modal: "625DI"},
{ sr: "", partno:"006001726A1", name: "Fan Belt", qty: "1", price: "325", modal: "625DI"},
{ sr: "", partno:"006039420H1", name: "Fan 380 DIA", qty: "1", price: "886", modal: "625DI"},
{ sr: "",partno:"006000456F1-PB", name: "Air Filter INNER", qty: "1", price: "557", modal: "625DI"},
{ sr: "",partno:"006000455F1-PB", name: "Air Filter OUTER", qty: "1", price: "713", modal: "625DI"},
{ sr: "",partno:"006036316H91", name: "Starter Motor", qty: "1", price: "10668", modal: "625DI"},
{ sr: "",partno:"006003265A94", name: "Pump Water Assy.", qty: "1", price: "5120", modal: "625DI"},
{ sr: "",partno:"000704436R1", name: "Starter Ring 843", qty: "1", price: "1715", modal: "625DI"},
{ sr: "", partno:"006047581H91", name: "Radiator Shroud Assy.", qty: "1", price: "7893", modal: "625DI"},
{ sr: "", partno:"N/A", name: "Service Charges", qty: "1", price: "1770", modal: "625DI"},
{ sr: "", partno:"N/A", name: "Transpotation", qty: "1", price: "236", modal: "625DI"},






]
// export default Data;