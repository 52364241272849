import React from 'react';

function QuotationCard({
  logo,
  engineSrNo,
  quotation,
  dealerName,
  customModelNo,
  modelCategory,
  date,
  quoteNumber,
  customerName,
  customerCity,
  customerState,
  modelNo,
  items,
}) {
  const totalAmount = items.reduce(
    (sum, item) => sum + item.qty * parseFloat(item.price),
    0
  );

  return (
    <div className="max-w-2xl mx-auto relative bg-white overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-no-repeat bg-center opacity-10 z-0"
        style={{ backgroundImage: "url('./images/img1.webp')" }}
      ></div>

      {/* Content */}
      <div className="relative z-10">
        <div className="flex justify-between items-center p-2">
          <div className="flex items-center">
            <img src={logo} alt="Company Logo" className="h-16 w-28 mr-2" />
          </div>
          <div className="text-right">
            <img
              src="./images/img.png"
              alt="Powerol Logo"
              className="h-12 w-24 mb-2"
            />
          </div>
        </div>
        <div className="border-b flex justify-between items-center py-2">
          <p className="text-sm px-2">Ref: {quoteNumber}</p>
          <p className="text-sm">Date: {date}</p>
        </div>
        <div className="flex flex-row-reverse">
          <p className="text-[12px]">GSTIN:-05AJBPS0455K1Z7 </p>
        </div>

        <div className="p-6">
          <h3 className="text-xl font-semibold mb-2 font-serif">To,</h3>
          <h3 className="text-xl font-semibold mb-2 px-4 font-serif">
            {customerName}
          </h3>
          <p className="text-sm mb-2 px-4 font-serif">{customerCity}</p>
          <p className="text-sm mb-2 px-4 font-serif">{customerState}</p>

          <p className="text-sm mb-4 px-4 font-[500]">
            Subject:{" "}
            {modelCategory === "IE"
              ? `Quotation for Model ${modelNo} Mahindra Engine Service.`
              : modelCategory === "OTHER"
              ? "Quotation for DG set Repairing"
              : `Quotation for Model ${modelNo} Mahindra DG set Service.`}
          </p>

          {/* Engine Sr. No. field for IE models */}
          {modelCategory === "IE" && (
            <p className="text-sm mb-4 px-4 font-[500]">
              Engine Sr. No.: {engineSrNo}
            </p>
          )}

          <p className="text-sm px-2">Dear sir,</p>
          <p className="text-sm mb-2 px-2 font-serif">
            Reference to your requirement, we are pleased to submit our cost as
            under.
          </p>

          <div className="overflow-x-auto">
            <table className="w-full mb-6">
              <thead>
                <tr className="bg-gray-100 bg-opacity-40">
                  <th className="px-2 py-1 text-left font-semibold">Sr. No.</th>
                  <th className="px-2 py-1 text-left font-semibold">Part No</th>
                  <th className="px-2 py-1 text-left font-semibold">
                    Description
                  </th>
                  <th className="px-2 py-1 text-right font-semibold">Qty</th>
                  <th className="px-2 py-1 text-right font-semibold">
                    Unit Price
                  </th>
                  <th className="px-2 py-1 text-right font-semibold">Total</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-2 py-1">{index + 1}</td>
                    <td className="px-2 py-1">{item.partno}</td>
                    <td className="px-2 py-1">{item.name}</td>
                    <td className="px-2 py-1 text-right">{item.qty}</td>
                    <td className="px-2 py-1 text-right">
                      {parseFloat(item.price).toFixed(2)}
                    </td>
                    <td className="px-2 py-1 text-right">
                      {(item.qty * parseFloat(item.price)).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Render the total separately */}
          <div className="font-bold text-right px-2">
            Total: {totalAmount.toFixed(2)}
          </div>

          <div className="mt-8 flex justify-between">
            <div className="">
              <h4 className="font-semibold mb-2">Terms and Conditions:</h4>

              <ul className="list-disc list-inside text-sm">
                <li>GST Inclusive.</li>
                <li>Payment terms: 100% advance.</li>
                <li>Validity: 30 days from the date of quotation.</li>
              </ul>
            </div>
            <div className="font-thin ">
              <p className="text-[11px] font-thin">
                <b>Scan This QR code for UPI Payment</b>
              </p>
              <img
                src="./images/qr.jpg"
                alt="qr"
                className="w-24 ml-7"
              />
              <p className="pt-2 text-[12px]">
                <b>Company's Bank Details</b>
              </p>
              <p className="text-[10px] ">
                Bank Name:-<b>SBI</b>
              </p>
              <p className="text-[10px]">
                A/c No. :-<b>65141147734</b>
              </p>
              <p className="text-[10px]">
                Branch:-<b>Gandhi Road Dehradun</b>
              </p>
              <p className="text-[10px]">
                IFS code:-<b>SBIN0007893</b>
              </p>
            </div>
          </div>
        </div>

        <div className="px-6 pb-6">
          <p>
            For <b>Sainko Systems</b>
          </p>
          <br />
          Authorized Signatory
        </div>

        <div className="border-b"></div>

        <div className="mt-4 text-xs text-center p-4">
          Showroom: AG1, PLS Plaza, 58 Gandhi Road, Dehradun – 248001
          <br />
          Ph. (O) 0135-3294457, 9897072024, E-mail: enqdgsets@gmail.com,
          sainkoservice@gmail.com
          <br />
          Service Center: H.No76 Green Avenue Colony, Chandrabani, Dehradun Mob.
          9411311312
          <br />
          E-mail: sainkoservice@gmail.com, Website: www.sainkosystems.com
        </div>

        <div className="bg-green-200 bg-opacity-80 text-center">
          <p className="text-sm">
            Design & Developed By Reactiveapps M. 8126813045
          </p>
        </div>
      </div>
    </div>
  );
}

export default QuotationCard;
