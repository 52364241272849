import React from 'react';
import './LandingPage.css';

function LandingPage({ onStartQuotation, onLogout }) {
  const letters = "QUOTATION".split("");

  return (
    <div className="page-container">
      {/* Background shapes */}
      <div className="shape shape-1"></div>
      <div className="shape shape-2"></div>
      <div className="shape shape-3"></div>
      <div className="shape shape-4"></div>
      <div className="shape shape-5"></div>

      {/* Content */}
      <div className="content-box max-w-md w-full mx-auto p-8 backdrop-blur-md bg-white bg-opacity-20 rounded-xl shadow-lg">
        <h1 className="text-5xl font-bold mb-8 text-center text-white">
          {letters.map((letter, index) => (
            <span key={index} className={`letter letter-${index + 1}`}>
              {letter}
            </span>
          ))}
        </h1>
        <div className="flex flex-col space-y-4">
          <button
            onClick={onStartQuotation}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
          >
            Start New Quotation
          </button>
          <button
            onClick={onLogout}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;