import React,{useState} from 'react';
import QuotationManager from './components/QuotationManager';
import Sale from './components/sales/Sale';
import QuotationFormSales from './components/sales/QuotationFormSales';
// import ReactToPrint from 'react-to-print';

function Apps() {
  const [currentForm, setCurrentForm] = useState('quotation');
  return (
    <div className="App">
      <QuotationManager />
      {/* <Sale/> */}
      {/* <div className="p-6 max-w-4xl mx-auto">
      <div className="flex justify-between mb-6">
        <button 
          onClick={() => setCurrentForm('quotation')} 
          className={`px-4 py-2 rounded ${currentForm === 'quotation' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Quotation Form
        </button>
        <button 
          onClick={() => setCurrentForm('sales')} 
          className={`px-4 py-2 rounded ${currentForm === 'sales' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Sales Form
        </button>
      </div>

      {currentForm === 'quotation' ? <QuotationFormSales />: "null" }
    </div> */}
    </div>
  );
}

export default Apps;